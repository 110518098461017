import { Fredcon24 } from 'components/marketingSite/fredcon24';
import HeadMetadata from 'components/shared/HeadMetadata';
import { useI18n } from 'hooks/useI18n';
import { usePageTrack } from 'hooks/usePageTrack';
import { WithBaseUrl } from 'interfaces/ui/with-base-url';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { APP_URLS } from 'utils/app-urls';
import { config } from 'utils/config';
import { getPublicPath } from 'utils/paths';

type ManTechCar24PageProps = WithBaseUrl & { manfreditesCount: number };

const ManTechCar24Page: React.FC<ManTechCar24PageProps> = ({ baseUrl }) => {
  const { t } = useI18n('fredcon24');

  usePageTrack('Landing - Page Loaded');

  return (
    <>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
        rel="stylesheet"
      ></link>

      <HeadMetadata
        title={t('fredcon.page_title')}
        url={APP_URLS.mantechcar24}
        desc={t('fredcon.page_description')}
        imgUrl={`${baseUrl}${getPublicPath(
          '/images/cards/card_fredcon24.png'
        )}`}
      />

      <link rel="canonical" href="https://fredcon.io" />
      <Fredcon24 />
    </>
  );
};

export default ManTechCar24Page;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || '', [
        'common',
        'topbar',
        'footer',
        'fredcon24'
      ])),
      baseUrl: config.appUrl
    }
  };
};
