import { Icon, Stack } from '@asgard/ui-library';
import {
  faLinkedin,
  faTelegram,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import HtmlTrans from 'components/shared/HtmlTrans';
import { useI18n } from 'hooks/useI18n';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Element, Link as LinkScroll } from 'react-scroll';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import { getPublicPath } from 'utils/paths';
import * as S from './Fredcon24.styled';

export const Fredcon24: React.FC = () => {
  const { t } = useI18n('fredcon24');
  const { locale, query } = useRouter();

  // https://getmanfred.typeform.com/to/HDUFBENj?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx
  const utmSource = query.utm_source?.toString();
  const utmMedium = query.utm_medium?.toString();
  const utmCampaign = query.utm_campaign?.toString();
  const utmTerm = query.utm_term?.toString();
  const utmConent = query.utm_content?.toString();

  const params = new URLSearchParams();

  if (utmSource) {
    params.append('utm_source', utmSource);
  }

  if (utmMedium) {
    params.append('utm_medium', utmMedium);
  }

  if (utmCampaign) {
    params.append('utm_campaign', utmCampaign);
  }

  if (utmTerm) {
    params.append('utm_term', utmTerm);
  }

  if (utmConent) {
    params.append('utm_content', utmConent);
  }

  const GetTicketButton = (
    <S.GetTicketButton
      href={`https://getmanfred.typeform.com/to/HDUFBENj?${params}`}
      target="_blank"
    >
      {t('fredcon.get-ticket')}
      <Icon icon={faArrowRight} />
    </S.GetTicketButton>
  );

  const agenda = [
    { break: false },
    { break: false },
    { break: false },
    { break: false },
    { break: false },
    { break: true },
    { break: false },
    { break: false },
    { break: false },
    { break: true },
    { break: false },
    { break: false },
    { break: false },
    { break: false },
    { break: true }
  ];

  return (
    <S.Root>
      <S.Fredon24GlobalStyles />
      <S.Header>
        <S.HeaderNav>
          <S.HeaderContainer>
            <Stack
              alignment="center"
              contentAlign="space-between"
              className="header"
            >
              <Stack alignment="center" gap="5xl">
                <Link href={getAppUrl(APP_URLS.home, locale)} passHref>
                  <S.HeaderNavLogo>
                    <img
                      src="https://media-asgard.s3.eu-west-1.amazonaws.com/manfred-media/logo_manfred_color.svg"
                      alt="Manfred logo"
                      className="brand"
                    />
                  </S.HeaderNavLogo>
                </Link>

                <Stack className="nav">
                  <LinkScroll href="#agenda" to="agenda">
                    {t('fredcon.header.nav-1')}
                  </LinkScroll>
                  <LinkScroll href="#donde" to="donde">
                    {t('fredcon.header.nav-2')}
                  </LinkScroll>
                  <LinkScroll href="#speakers" to="speakers">
                    {t('fredcon.header.nav-3')}
                  </LinkScroll>
                  <LinkScroll href="#entradas" to="entradas">
                    {t('fredcon.header.nav-4')}
                  </LinkScroll>
                  <LinkScroll href="#contacto" to="contacto">
                    {t('fredcon.header.nav-5')}
                  </LinkScroll>
                </Stack>
              </Stack>

              {GetTicketButton}
            </Stack>
          </S.HeaderContainer>
        </S.HeaderNav>
        <S.HeaderNotice>
          <S.HeaderContainer>
            <HtmlTrans ns="fredcon24" i18nKey="fredcon.header.notice" />
          </S.HeaderContainer>
        </S.HeaderNotice>
      </S.Header>

      <S.Container>
        <S.Hero>
          <h1>
            <span>{t('fredcon.hero.title-pre')}</span> {t('fredcon.hero.title')}
          </h1>

          <S.HeroDesc>
            <HtmlTrans ns="fredcon24" i18nKey="fredcon.hero.desc" />
          </S.HeroDesc>

          <S.HeroDate>
            <HtmlTrans ns="fredcon24" i18nKey="fredcon.hero.date" />
          </S.HeroDate>

          <Stack wrap>
            {Array.from({ length: 12 }).map((_, index) => (
              <S.HeroHost key={index}>
                <img
                  src={getPublicPath(`/images/fredcon24/host-${index + 1}.png`)}
                  alt={t(`fredcon.hero.hosts.host-${index + 1}.name`)}
                />
                <div>
                  <strong>
                    {t(`fredcon.hero.hosts.host-${index + 1}.name`)}
                  </strong>
                  <span>{t(`fredcon.hero.hosts.host-${index + 1}.desc`)}</span>
                </div>
              </S.HeroHost>
            ))}
          </Stack>
        </S.Hero>

        <S.What>
          <h2>{t('fredcon.what.title')}</h2>

          <S.WhatDesc>
            <HtmlTrans ns="fredcon24" i18nKey="fredcon.what.desc" />
          </S.WhatDesc>
        </S.What>

        <S.Where>
          <Element name="donde" id="donde"></Element>
          <h2>{t('fredcon.where.title')}</h2>

          <S.WhereWrapper>
            <S.WhereDesc>
              <HtmlTrans ns="fredcon24" i18nKey="fredcon.where.desc" />
            </S.WhereDesc>

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.840585624863!2d-3.719965751984382!3d40.41238218675317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd422875fe8d546d%3A0xfc628be71587461f!2sGoogle%20for%20Startups%20Campus!5e0!3m2!1ses!2ses!4v1728310710366!5m2!1ses!2ses"
              style={{ border: 0 }}
              allowFullScreen={false}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </S.WhereWrapper>
        </S.Where>

        <S.Agenda>
          <Element name="agenda" id="agenda"></Element>
          <h2>{t('fredcon.agenda.title')}</h2>

          <Stack direction="column">
            {agenda.map((item, index) => (
              <S.AgendaItem key={index} isBreak={item.break}>
                <S.AgendaItemTime>
                  {t(`fredcon.agenda.agenda-${index + 1}.time`)}
                </S.AgendaItemTime>

                <S.AgendaItemContent>
                  <HtmlTrans
                    ns="fredcon24"
                    i18nKey={`fredcon.agenda.agenda-${index + 1}.content`}
                  />
                </S.AgendaItemContent>
              </S.AgendaItem>
            ))}
          </Stack>
        </S.Agenda>

        <S.Speakers>
          <Element name="speakers" id="speakers"></Element>
          <h2>{t('fredcon.speakers.title')}</h2>

          <S.SpeakersDesc>
            <HtmlTrans ns="fredcon24" i18nKey="fredcon.speakers.desc" />
          </S.SpeakersDesc>

          <Stack wrap gap="l">
            {Array.from({ length: 14 }).map((_, index) => (
              <S.Speaker key={index}>
                <div>
                  <img
                    src={getPublicPath(
                      `/images/fredcon24/speaker-${index + 1}.png`
                    )}
                    alt={t(`fredcon.speakers.speaker-${index + 1}.name`)}
                  />

                  <span>
                    <strong>
                      {t(`fredcon.speakers.speaker-${index + 1}.name`)}
                    </strong>
                    <span>
                      {t(`fredcon.speakers.speaker-${index + 1}.desc`)}
                    </span>
                  </span>
                </div>
                <a
                  href={t(`fredcon.speakers.speaker-${index + 1}.linkedin`)}
                  target="_blank"
                >
                  <Icon icon={faLinkedin} size="xl" />
                </a>
              </S.Speaker>
            ))}
          </Stack>
        </S.Speakers>

        <S.Tickets>
          <Element name="entradas" id="entradas"></Element>
          <h2>{t('fredcon.tickets.title')}</h2>

          <S.TicketsWrapper>
            <h1>
              <span>{t('fredcon.hero.title-pre')}</span>{' '}
              {t('fredcon.hero.title')}
            </h1>

            <S.TicketsDesc>
              <HtmlTrans ns="fredcon24" i18nKey="fredcon.tickets.desc" />
            </S.TicketsDesc>

            <div>{GetTicketButton}</div>
          </S.TicketsWrapper>
        </S.Tickets>

        <S.Partners>
          <Element name="partners" id="partners"></Element>
          <h2>{t('fredcon.partners.title')}</h2>

          <Stack direction="column" gap="l">
            <Stack wrap gap="l" alignment="center">
              {Array.from({ length: 4 }).map((_, index) => (
                <S.Partner
                  key={index}
                  href={t(`fredcon.partners.partner-${index + 1}.url`)}
                  target="_blank"
                >
                  <img
                    src={getPublicPath(
                      `/images/fredcon24/partner-${index + 1}.svg`
                    )}
                    alt={t(`fredcon.partners.partner-${index + 1}.name`)}
                  />
                </S.Partner>
              ))}
            </Stack>

            <Stack wrap gap="l" alignment="center">
              {Array.from({ length: 3 }).map((_, index) => (
                <S.Partner
                  key={index}
                  href={t(`fredcon.partners.partner-${index + 1 + 4}.url`)}
                  target="_blank"
                >
                  <img
                    src={getPublicPath(
                      `/images/fredcon24/partner-${index + 1 + 4}.svg`
                    )}
                    alt={t(`fredcon.partners.partner-${index + 1 + 4}.name`)}
                  />
                </S.Partner>
              ))}
            </Stack>
          </Stack>
        </S.Partners>

        <S.Footer>
          <Element name="contacto" id="contacto"></Element>

          <S.FooterContact>
            <HtmlTrans ns="fredcon24" i18nKey="fredcon.footer.contact" />
          </S.FooterContact>

          <S.FooterInfo>
            <Stack contentAlign="space-between">
              <h1>
                <span>{t('fredcon.hero.title-pre')}</span>{' '}
                {t('fredcon.hero.title')}
              </h1>

              <img
                src={getPublicPath('/images/fredcon24/manfred-tecnofor.svg')}
                alt="Manfred + Tecnofor"
              />
            </Stack>

            <nav>
              <span>
                © {new Date().getFullYear()} <strong>Manfred Tech</strong> S.L.
              </span>
              <a href={getAppUrl(APP_URLS.termsOfUse)} target="_blank">
                {t('fredcon.footer.nav.terms')}
              </a>
              <a href={getAppUrl(APP_URLS.privacyPolicy)} target="_blank">
                {t('fredcon.footer.nav.privacy')}
              </a>
              <a href={getAppUrl(APP_URLS.cookies)} target="_blank">
                {t('fredcon.footer.nav.cookies')}
              </a>
              <a href="https://twitter.com/getmanfred" target="_blank">
                <Icon icon={faTwitter} size="l" />
              </a>
              <a href="https://t.me/getmanfred" target="_blank">
                <Icon icon={faTelegram} size="l" />
              </a>
            </nav>
          </S.FooterInfo>
        </S.Footer>
      </S.Container>
    </S.Root>
  );
};
